import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../config";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { useMyContext } from "../context/Usecontext";

const CreateEmployee = ({ setIslogin }) => {
  const formRef = useRef(null);
  const { dataforedit, setDataforedit } = useMyContext();
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();

  const onsubmit = async (data) => {
    if (edit) {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            Authorization: token,
          },
        };
        const id = dataforedit.id;
        const editobj = {
          ...data,
          id: id,
        };

        const response = await axios.post(
          `${conf.baseurl}/updateemployee`,
          editobj,
          config
        );
        if (response.data.status === "F") {
          toast.error(response.data.message);
        } else {
          formRef.current.resetFields();
          toast.success(response.data.message);
          setEdit(false);
          navigate("/employeelist");
          setDataforedit(null);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired, Please login again");

          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          console.log("Error in deleting data:", error.message);
        }
      }
    } else {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            Authorization: token,
          },
        };
        const response = await axios.post(
          `${conf.baseurl}/addemployee`,
          data,
          config
        );
        if (response.data.status === "F") {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          formRef.current.resetFields();
          navigate("/employeelist");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired, Please login again");

          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          console.log("Error in deleting data:", error.message);
        }
      }
    }
  };

  useEffect(() => {
    if (dataforedit != null) {
      setEdit(true);
      formRef.current.setFieldsValue({
        fname: dataforedit.fname,
        lname: dataforedit.lname,
        mail: dataforedit.mail,
        // password: dataforedit.password,
        SSN: dataforedit.SSN,
        status: dataforedit.status,
      });
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="formWrapper" style={{ width: 600 }}>
        <Form
          ref={formRef}
          name="basic"
          initialValues={{ remember: true, status: "active" }}
          layout="vertical"
          onFinish={onsubmit}
        >
          <Form.Item
            label="First Name"
            name="fname"
            rules={[{ required: true, message: "Please enter first name." }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lname"
            rules={[{ required: true, message: "Please enter last name." }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email Id / Username"
            name="mail"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className="password-field"
            label="Password"
            name="password"
            rules={[
              {
                required: edit ? false : true,
                message: "Please enter password.",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="SSN"
            name="SSN"
            rules={[
              {
                required: true,
                message: "Please enter SSN number.",
              },
              {
                pattern: /^\d{4}$/,
                message: "Please enter valid 4-digit SSN number.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Please select a status." }]}
          >
            <Select placeholder="Select status">
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="terminated">Terminated</Select.Option>
            </Select>
          </Form.Item>

          <div>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit">
                {edit ? "Save" : "Submit"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateEmployee;
