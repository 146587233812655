import { Button, Form, Input } from "antd";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../config";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { useMyContext } from "../context/Usecontext";

const Editpromotetableform = ({ setIslogin }) => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { promotedataforedit, setPromotedataforedit } = useMyContext();
  const [addpromotedata, setAddpromotedata] = useState(false);

  useEffect(() => {
    if (promotedataforedit != null) {
      formRef.current.setFieldsValue({
        Fund: promotedataforedit.Fund,
        Amount: promotedataforedit.Amount,
        "BVCM %": promotedataforedit["BVCM %"],
        Start: promotedataforedit.Start,
        End: promotedataforedit.End,
      });
    } else {
      setAddpromotedata(true);
    }
  }, []);

  const onsubmit = async (data) => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      if (addpromotedata) {
        const response = await axios.post(
          `${conf.baseurl}/addpromotedata`,
          data,
          config
        );
        if (response.data.status === "S") {
          toast.success(response.data.message);
          navigate("/promotelist");
        } else {
          toast.error(response.data.message);
        }
      } else {
        const editobj = {
          ...data,
          id: promotedataforedit._id,
        };
        const response = await axios.put(
          `${conf.baseurl}/editpromotedata`,
          editobj,
          config
        );
        if (response.data.status === "S") {
          toast.success(response.data.message);
          navigate("/promotelist");
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log(error.message);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="formWrapper" style={{ width: 600 }}>
        <Form
          ref={formRef}
          name="basic"
          initialValues={{
            Fund: null,
            Amount: null,
            "BVCM %": null,
            Start: null,
            End: null,
          }}
          layout="vertical"
          onFinish={onsubmit}
        >
          <Form.Item
            label="Fund"
            name="Fund"
            rules={[
              {
                required: true,
                message: "Please input the fund name!", // Message to show when validation fails
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="Amount"
            rules={[
              {
                pattern: /^\d*\.?\d*$/,
                message: "only numbers are allowed.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="BVCM %"
            name="BVCM %"
            rules={[
              {
                pattern: /^\d*\.?\d*$/,
                message: "only numbers are allowed.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Start"
            name="Start"
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: "only numbers are allowed.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="End"
            name="End"
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: "only numbers are allowed.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit">
                {addpromotedata ? "Submit" : "Save"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default Editpromotetableform;
