import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Table, Space, Spin, Modal, Tooltip, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { MdAddCircleOutline } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { useMyContext } from "../context/Usecontext";
import conf from "../config";
import Cookies from "js-cookie";
import { AiOutlineDelete } from "react-icons/ai";
import { FaWpforms } from "react-icons/fa6";
import { FaArchive } from "react-icons/fa";
import { GoArchive } from "react-icons/go";

const EmployeeList = ({ setIslogin }) => {
  const { dataforedit, setDataforedit } = useMyContext();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [deleteid, setDeleteid] = useState();
  const { header, setHeader } = useMyContext();
  const { fname, setFname } = useMyContext();
  const { lname, setLname } = useMyContext();
  const [originaldata, setOriginalData] = useState([]);

  const toggleArchive = async (updateid) => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const id = updateid;
      const response = await axios.put(
        `${conf.baseurl}/archive/${id}`,
        {},
        config
      );
      if (response.data.status === "S") {
        toast.success(response.data.message);
        getempdata();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else if (error.response && error.response.status === 403) {
        // console.log(error.response);
        toast.error(error.response.data.message);
      } else {
        console.log("Error in archieve data:", error.message);
      }
    }
  };

  const getempdata = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/getdetails`, config);
      if (response.data == "F") {
        console.log("No data available");
        setLoading(false);
      } else {
        const data = response.data.filter((item) => item.isAdmin != 1);
        const sortedData = data.sort((a, b) => {
          return a.fname.localeCompare(b.fname);
        });
        setData(sortedData);
        setOriginalData(sortedData);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        setLoading(false);
        console.log("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    getempdata();
  }, []);

  const columns = [
    {
      title: "Firstname",
      dataIndex: "fname",
      key: "fname",
    },
    {
      title: "Lastname",
      dataIndex: "lname",
      key: "lname",
    },
    {
      title: "Email",
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: "SSN",
      dataIndex: "SSN",
      key: "SSN",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit Employee" color="green">
            <Button type="primary" onClick={() => getdataforedit(record)}>
              <FiEdit />
            </Button>
          </Tooltip>
          {fname === "BV" && lname === "Admin" && (
            <Tooltip title="Delete Employee Record" color="red">
              <Button
                type="primary"
                onClick={() => {
                  setDeleteid(record.id);
                  setShowmodal(true);
                }}
              >
                <AiOutlineDelete color={"red"} />
              </Button>
            </Tooltip>
          )}

          <Tooltip
            title={record.isArchive ? "Unarchive Record" : "Archive Record"}
            color={record.isArchive ? "blue" : "orange"}
          >
            <Button type="primary" onClick={() => toggleArchive(record.id)}>
              {record.isArchive ? <FaArchive /> : <GoArchive />}
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getdataforedit = (data) => {
    setDataforedit(data);
    navigate("/create");
  };

  const deleteEmployee = async () => {
    try {
      const token = Cookies.get("token"); // Replace 'yourTokenCookieName' with the actual name of your token cookie
      const config = {
        headers: {
          Authorization: token,
        },
      };

      // const id=data.id;
      const id = deleteid;
      const response = await axios.delete(
        `${conf.baseurl}/delete_employee/${id}`,
        config
      );
      if (response.data.status === "S") {
        toast.success(response.data.message);
        setShowmodal(false);
        setDeleteid();
        getempdata();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else if (error.response && error.response.status === 403) {
        // console.log(error.response);
        toast.error(error.response.data.message);
      } else {
        console.log("Error in deleting data:", error.message);
      }
    }
  };

  const handleCancel = () => {
    setShowmodal(false);
    setDeleteid();
  };

  const handleSortChange = (e) => {
    let sortedData;
    if (e.target.value === "firstname") {
      sortedData = [...data].sort((a, b) => {
        return a.fname.localeCompare(b.fname);
      });
    } else if (e.target.value === "lastname") {
      sortedData = [...data].sort((a, b) => {
        return a.lname.localeCompare(b.lname);
      });
    }
    setData(sortedData);
  };
  return (
    <div className="container">
      <Spin spinning={loading}>
        <div className="formWrapper">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <Button
                className="defaultBtns"
                onClick={() => {
                  setDataforedit(null);
                  navigate("/create");
                }}
              >
                <MdAddCircleOutline />
                Add
              </Button>
              <Select
                showSearch
                placeholder="Select option to filter records by"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  { value: "active", label: "Active" },
                  { value: "terminated", label: "Terminated" },
                  { value: "archived", label: "archived" },
                ]}
                allowClear
                onChange={(value) => {
                  const updatedData = [...originaldata];

                  // console.log("Original data:", updatedData);
                  // console.log("Selected filter:", value);

                  // Filter based on the selected value
                  let filterData = updatedData; // Default to original data

                  if (value === "terminated" || value === "active") {
                    filterData = updatedData.filter(
                      (item) => item.status === value.toLowerCase()
                    );
                  } else if (value === "archived") {
                    filterData = updatedData.filter(
                      (item) => item.isArchive === true
                    );
                  }

                  // Update state with the filtered data
                  setData(filterData);
                }}
                style={{ width: 200, marginLeft: 20 }}
              />
            </div>
            <select
              style={{ borderRadius: 7, padding: "4" }}
              onChange={handleSortChange}
            >
              <option value="firstname">Sort by Firstname</option>
              <option value="lastname">Sort by Lastname</option>
            </select>
          </div>

          <Table
            columns={columns}
            bordered={true}
            dataSource={data}
            style={{ overflow: "auto", margin: 10 }}
            pagination={false}
            align="center"
            className="grid-table"
          />
        </div>
      </Spin>

      <Modal open={showmodal} onOk={deleteEmployee} onCancel={handleCancel}>
        <p>
          Are you sure you want to delete this record? This action will
          permanently delete all the information related to the employee.
        </p>
      </Modal>
    </div>
  );
};
export default EmployeeList;
