import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Select, Checkbox, Row, Col, Spin } from "antd";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import conf from "../config";
import { useNavigate } from "react-router-dom";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useMyContext } from "../context/Usecontext";

const { RangePicker } = DatePicker;

const Exportpage = ({ setIslogin }) => {
  const navigate = useNavigate();
  const [selectedYears, setSelectedYears] = useState([]);
  const [data, setData] = useState([]);
  const [empid, setImpid] = useState();
  const formRef = useRef(null);
  const [colmarray, setColmarray] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [allSelect, setAllselect] = useState(false);
  const [allids, setAllids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sheetdata, setSheetdata] = useState(false);
  const [promoteData, setPromoteData] = useState([]);
  const [fields, setFields] = useState([]);

  let all = [
    "Department",
    "Manager",
    "Title",
    "extrabonus",
    "Match",
    "salary",
    "lowbonusestimate",
    "highbonusestimate",
    "bonusTarget",
    "actualBonus",
    "medical",
    "lifeinsurance",
    "Dental",
    "vision",
    "cohort1",
    "cohort2",
    "cohort5",
    "cohort6",
    "hurdle_income1",
    "hurdle_income2",
    "hurdle_income5",
    "hurdle_income6",
    "share1",
    "share2",
    "share5",
    "share6",
    ...fields,
  ];
  const getdetails = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/getdetails`, config);
      if (response.data == "F") {
        console.log("no data available");
      } else {
        const data = response.data.filter((item) => item.isAdmin != 1);
        setData(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error:", error.message);
      }
    }
  };
  const getpromotedata = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getpromotedata`,
        config
      );
      if (response.data.status == "F") {
        // console.log(response.data);
        console.log("no data available");
      } else {
        setPromoteData(response.data.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error fetching data:", error.message);
      }
    }
  };
  useEffect(() => {
    getdetails();
    getpromotedata();
  }, []);

  useEffect(() => {
    // Combine field names from promoteData based on all four sections
    const combinedFields = promoteData.flatMap((fund) => [
      `Promote_Distributions_${fund.Fund}`,
      `Fund_allocations_${fund.Fund}`,
      `EPP_Promote_Distributions_${fund.Fund}`,
      `EPP_Promote_Allocations_${fund.Fund}`,
    ]);

    setFields(combinedFields);
  }, [promoteData]);

  const handleYearChange = (dates, dateStrings) => {
    // console.log("Selected Years:", dateStrings);
    setSelectedYears(dateStrings);
  };
  const disabledDate = (current) => {
    // Disable dates greater than the current year
    return current && current.year() > new Date().getFullYear();
  };

  const handleEmployeeChange = (value) => {
    if (value === "all") {
      const allEmployeeIds = data.map((val) => val.id);
      // console.log(allEmployeeIds);
      setAllids(allEmployeeIds);
    } else {
      // It's not an array, so directly set empid
      const empid = value;
      // console.log("Employee ID:", empid);
      setImpid(empid);
    }
  };

  const exportexcel = async () => {
    console.log(selectedValues);
    try {
      setLoading(true);
      if (!empid || allids.length == 0) {
        var allempids = data.map((val) => val.id);
        // console.log(allempids);
        setAllids(allempids);
      }
      if (selectedValues.length === 0) {
        setSelectedValues(all);
      }
      // console.log("Submitted:", selectedYears);
      const token = Cookies.get("token");
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const data2 = {
        start: selectedYears[0],
        end: selectedYears[1],
        id: empid ? empid : allids.length > 0 ? allids : allempids,
        columnsdata: selectedValues.length === 0 ? all : selectedValues,
        datainsheets: sheetdata,
      };
      const response = await axios.post(`${conf.baseurl}/excel`, data2, {
        responseType: "blob",
        headers: config.headers,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "Bluevista LLC compensation report.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setImpid();
      setColmarray([]);
      formRef.current.resetFields();
      setLoading(false);
      setSheetdata(false);
      // formRef.current.setFieldsValue({ 'checkboxFieldName': [] });
      setSelectedValues([]);
      setAllselect(false);
      formRef.current.setFieldsValue({ rangePickerFieldName: undefined });
      // }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error:", error.message);
      }
    }
  };

  const colms = [
    {
      section: "",
      columns: [
        { label: "Department", value: "Department" },
        { label: "Manager", value: "Manager" },
        { label: "Title", value: "Title" },
      ],
    },

    {
      section: "Extrabonus",
      columns: [{ label: "Extrabonus", value: "extrabonus" }],
    },
    {
      section: "Match",
      columns: [{ label: "Match", value: "Match" }],
    },

    {
      section: "Salary Bonus",
      columns: [
        { label: "Salary", value: "salary" },
        { label: "Lowbonusestimate", value: "lowbonusestimate" },
        { label: "Highbonusestimate", value: "highbonusestimate" },
        // { label: "Bonusestimate", value: "bonusestimate" },
        { label: "BonusTarget", value: "bonusTarget" },
        { label: "ActualBonus", value: "actualBonus" },
        // { label: "ActualBonusReserve", value: "actualBonusReserve" },
      ],
    },

    {
      section: "Insurance",
      columns: [
        { label: "Medical", value: "medical" },
        { label: "Lifeinsurance", value: "lifeinsurance" },
        { label: "Dental", value: "Dental" },
        { label: "Vision", value: "vision" },
      ],
    },

    {
      section: "Promote Distributions",
      columns: promoteData.map((fund) => ({
        label: fund.Fund,
        value: `Promote_Distributions_${fund.Fund}`,
      })),
    },

    {
      section: "Fund allocations",
      columns: promoteData.map((fund) => ({
        label: fund.Fund,
        value: `Fund_allocations_${fund.Fund}`,
      })),
    },

    {
      section: "EPP Promote Distributions",
      columns: promoteData.map((fund) => ({
        label: fund.Fund,
        value: `EPP_Promote_Distributions_${fund.Fund}`,
      })),
    },

    {
      section: "EPP Promote Allocations",
      columns: promoteData.map((fund) => ({
        label: fund.Fund,
        value: `EPP_Promote_Allocations_${fund.Fund}`,
      })),
    },

    {
      section: "Net Income",
      columns: [
        { label: "Cohort1", value: "cohort1" },
        { label: "Cohort2", value: "cohort2" },
        { label: "Cohort5", value: "cohort5" },
        { label: "Cohort6", value: "cohort6" },
        { label: "Hurdle_Income_1", value: "hurdle_income1" },
        { label: "Hurdle_Income_2", value: "hurdle_income2" },
        { label: "Hurdle_Income_5", value: "hurdle_income5" },
        { label: "Hurdle_Income_6", value: "hurdle_income6" },
        { label: "Share1", value: "share1" },
        { label: "Share2", value: "share2" },
        { label: "Share5", value: "share5" },
        { label: "Share6", value: "share6" },
      ],
    },
  ];

  const disabledFutureYears = (current) => {
    // Disable dates in the future, i.e., after the current year
    // return current && current.year() > dayjs().year();
    const currentYear = dayjs().year();
    const year = dayjs(current).year();
    return current && year > currentYear && year !== 2025;
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("onFinishFailed triggered");
    if (!selectedYears || selectedYears.length !== 2) {
      // Scroll to the top of the form container
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <div className="container">
      <Spin spinning={loading}>
        <div className="formWrapper">
          <Form
            ref={formRef}
            onFinish={exportexcel}
            layout="vertical"
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={6}>
                <Form.Item
                  label="Select Years"
                  name="rangePickerFieldName"
                  rules={[{ required: true, message: "Please select years" }]}
                >
                  <RangePicker
                    picker="year"
                    onChange={handleYearChange}
                    allowClear={false}
                    disabledDate={disabledFutureYears}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Select Employee" name="employee">
                  <Select
                    onChange={handleEmployeeChange}
                    placeholder="Select employee"
                  >
                    <Select.Option value="all" key="all">
                      All Employees
                    </Select.Option>
                    <Select.Option value="active" key="active">
                      Active
                    </Select.Option>
                    <Select.Option value="terminated" key="terminated">
                      Terminated
                    </Select.Option>
                    {data.map((val) => (
                      <Select.Option value={val.id} key={val.id}>
                        {val.fname + " " + val.lname + " " + val.mail}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="checkboxFieldName" label="Select Columns">
                  <Checkbox
                    checked={sheetdata}
                    label="Sheets"
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setSheetdata(e.target.checked);
                    }}
                  >
                    Create separate sheet for each year
                  </Checkbox>

                  <Checkbox
                    checked={allSelect}
                    options={colms}
                    label="select All"
                    onChange={(e) => {
                      e.target.checked
                        ? setSelectedValues(all)
                        : setSelectedValues([]);
                      setAllselect(e.target.checked);
                    }}
                  >
                    Select All
                  </Checkbox>

                  <Checkbox.Group
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column", // Display checkboxes in a column
                      alignItems: "flex-start", // Align checkboxes to the start of each row
                    }}
                    value={selectedValues}
                    onChange={(e) => {
                      setSelectedValues(e);
                      e.length === 90
                        ? setAllselect(true)
                        : setAllselect(false);
                    }}
                  >
                    {colms.map((val1) => (
                      <div style={{ marginTop: "10px", width: "100%" }}>
                        <h6> {val1.section}</h6>
                        <Row gutter={[16, 16]}>
                          {val1.columns?.map((val) => (
                            <Col key={val.value} span={6}>
                              <Checkbox value={val.value}>{val.label}</Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default Exportpage;
