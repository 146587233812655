import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  InputNumber,
  Spin,
} from "antd";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import conf from "../config";
import { ToastContainer, toast } from "react-toastify";
import { LuEye } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../context/Usecontext";
import Cookies from "js-cookie";

const YourComponent = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
const Report = ({ setIslogin }) => {
  const formRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { downloading, setDownloading } = useMyContext();
  const { isAdmin, setIsAdmin } = useMyContext();

  const getdetails = async () => {
    try {
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/getdetails`, config);
      if (response.data == "F") {
        console.log("no data available");
      } else {
        const data = response.data.filter((item) => item.isAdmin != 1);
        setData(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    getdetails();
    const isAdmin = Cookies.get("isAdmin");
    setIsAdmin(isAdmin);
  }, []);
  const [response, setresponse] = useState();

  const onFinish = async (data) => {
    setLoading(true);
    const { year, ...restData } = data;
    const formattedYear = dayjs(year).format("YYYY");
    const updatedData = { ...restData, year: formattedYear, function: 1 };
    try {
      //   console.log(data);
      const token = Cookies.get("token");
      //   console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.post(
        `${conf.baseurl}/generatereport`,
        updatedData,
        config
      );

      if (response.data.message == "S") {
        setresponse(response.data.html);
        setLoading(false);
        toast.success("Report is generated successfully.", {
          position: "top-center",
        });
        setShow(true);
        // formRef.current.resetFields();
      } else {
        toast.success("No data available for generating the report.", {
          position: "top-center",
        });
        setLoading(false);
        setresponse();
        formRef.current.resetFields();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // setLoading(false)
        console.log("Error submitting form:", error.message);
      }
    }
  };

  const mailreport = async () => {
    setLoading(true);
    // setresponse()

    const values = formRef.current.getFieldsValue();
    // console.log(values);
    if (!values.year || !values.info) {
      // Display toast message if required fields are empty
      toast.error("Please select year and employee.", {
        position: "top-center",
      });
      setLoading(false);
      return;
    } else {
      const { year, ...restData } = values;
      const formattedYear = dayjs(year).format("YYYY");
      const updatedData = { ...restData, year: formattedYear, function: 2 };
      // console.log(updatedData);

      try {
        // console.log(data);
        const token = Cookies.get("token");
        // console.log(token);
        const config = {
          headers: {
            Authorization: token,
          },
        };
        const response = await axios.post(
          `${conf.baseurl}/generatereport`,
          updatedData,
          config
        );

        if (response.data.message == "S") {
          setLoading(false);
          toast.success("Mail is sent successfully.", {
            position: "top-center",
          });
          setLoading(false);
        } else {
          toast.success("No data available for sending the report.", {
            position: "top-center",
          });
          setLoading(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page
          toast.error("Session expired, Please login again");
          setLoading(false);
          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          console.log("Error submitting form:", error.message);
        }
      }
    }
  };

  const downloadreport = async () => {
    console.log("download report");
    setLoading(true);
    // setresponse()
    const values = formRef.current.getFieldsValue();
    // console.log(values);

    if (!values.year || (!values.info && isAdmin == 1)) {
      toast.error("Please select year and employee.", {
        position: "top-center",
      });
      setLoading(false);
      return;
    } else {
      // console.log("All form values:", values);
      const { year, ...restData } = values;
      const formattedYear = dayjs(year).format("YYYY");
      const updatedData = { ...restData, year: formattedYear, function: 3 };

      try {
        const token = Cookies.get("token");
        // console.log(token);
        const config = {
          headers: {
            Authorization: token,
          },
        };
        const response = await axios.post(
          `${conf.baseurl}/generatereport`,
          updatedData,
          { responseType: "blob", headers: config.headers }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "Bluevista LLC compensation report.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page
          toast.error("Session expired, Please login again");
          setLoading(false);
          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          // Handle other errors
          // setLoading(false)
          setLoading(false);
          console.error("Error generating PDF:", error);

          toast.error("Error generating PDF. Please try again later.", {
            position: "top-center",
          });

          console.log("Error submitting form:", error.message);
        }
      }
    }
  };

  function disabledFutureYear(current) {
    // return current && dayjs(current).year() > dayjs().year();
    const currentYear = dayjs().year();
    const year = dayjs(current).year();
    return current && year > currentYear && year !== 2025;
  }

  return (
    <div className="container">
      <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          layout="vertical"
          style={{ margin: 20 }}
          ref={formRef}
        >
          <div className="formWrapper">
            <Row>
              <Col span={11}>
                <Form.Item
                  label="Select Year"
                  name="year"
                  rules={[{ required: true, message: "Please enter the year" }]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    onChange={(year) => {
                      console.log(year);
                      setresponse();
                      setShow(false);
                    }}
                    disabledDate={disabledFutureYear}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              {isAdmin == 1 && (
                <Col span={11}>
                  <Form.Item
                    label="Select Employee"
                    name="info"
                    rules={[
                      { required: true, message: "Please select employee" },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={() => {
                        setresponse();
                        setShow(false);
                      }}
                    >
                      {data.map((val) => {
                        return (
                          <Select.Option value={val.id} key={val.id}>
                            {val.fname + " " + val.lname + " " + val.mail}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col>
                <div className="report-btns">
                  <Button type="primary" htmlType="submit" className="view-btn">
                    <LuEye size={20} />
                  </Button>
                </div>
              </Col>

              <div className="reportWrapper">
                {show && (
                  <div className="reportBtns">
                    {isAdmin == 1 && (
                      <Button
                        type="primary"
                        onClick={mailreport}
                        className="mail-btn"
                      >
                        <HiOutlineMail />
                      </Button>
                    )}

                    <Button
                      type="primary"
                      onClick={downloadreport}
                      className="download-btn"
                    >
                      <AiOutlineCloudDownload />
                    </Button>
                  </div>
                )}
                <YourComponent
                  htmlContent={response ? response : null}
                ></YourComponent>
              </div>
            </Row>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
export default Report;
