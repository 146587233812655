import { Button, Form, Input } from "antd";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import conf from "../config";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useMyContext } from "../context/Usecontext";

const Loginpage = ({ handleLoginSuccess }) => {
  const navigate = useNavigate();
  const { isAdmin, setIsAdmin } = useMyContext();
  const { fname, setFname } = useMyContext();
  const { lname, setLname } = useMyContext();

  // const encodeToken = (token) => {
  //   return btoa(token); // Base64 encoding
  // };

  const onFinish = async (values) => {
    try {
      const response = await axios.post(`${conf.baseurl}/login`, values);
      if (response.status === 200) {
        toast.success("Login successful.");
        if (response.data.isAdmin == 1) {
          const hours = 6;
          const now = new Date();
          const expires = new Date(now.getTime() + hours * 60 * 60 * 1000);
          navigate("/showdata");
          setIsAdmin(response.data.isAdmin);
          setFname(response.data.firstname);
          setLname(response.data.lastname);
          const token = response.data.token;
          // console.log(token);
          // const encodedToken = encodeToken(token);
          // console.log(encodedToken);
          Cookies.set("token", response.data.token, { expires });
          Cookies.set("isAdmin", response.data.isAdmin, { expires });
          Cookies.set("isLoggedIn", "true", { expires });
          Cookies.set("firstname", response.data.firstname);
          Cookies.set("lastname", response.data.lastname);
          handleLoginSuccess();
        } else {
          navigate("/report");
          const hours = 6;
          const now = new Date();
          const expires = new Date(now.getTime() + hours * 60 * 60 * 1000);
          //   console.log(response.data.isAdmin);
          setIsAdmin(response.data.isAdmin);
          setFname(response.data.firstname);
          setLname(response.data.lastname);
          Cookies.set("token", response.data.token, { expires });
          Cookies.set("isAdmin", response.data.isAdmin, { expires });
          Cookies.set("isLoggedIn", "true", { expires });
          Cookies.set("firstname", response.data.firstname);
          Cookies.set("lastname", response.data.lastname);

          handleLoginSuccess();
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        const status = error.response.status;
        toast.error(error.response.data.message);
        // if (status === 401) {
        //   // Handle unauthorized login attempts (invalid credentials)
        //   toast.error(
        //     error.response.data.message ||
        //       "Invalid credentials. Please try again."
        //   );
        // } else if (status === 403) {
        //   // Handle forbidden access (user is blocked)
        //   toast.error(
        //     error.response.data.message || "Your account is blocked."
        //   );
        // } else if (status === 404) {
        //   // Handle user not found
        //   toast.error(error.response.data.message || "User not found.");
        // } else {
        //   // Handle other status codes
        //   toast.error(
        //     error.response.data.message ||
        //       "An error occurred. Please try again."
        //   );
        // }
      } else {
        // Network or unexpected errors
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className="login-screen">
      <div className="loginBox">
        <div className="login-logo">
          <img src={require("../assets/login-logo.png")}></img>
        </div>
        <div className="loginForm">
          <Form
            name="loginForm"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="username"
              rules={[
                { required: true, message: "Please enter your username" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password / SSN"
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Loginpage;
