import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Table,
  Space,
  Spin,
  Modal,
  Select,
  DatePicker,
  Tooltip,
  Row,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";

import { MdAddCircleOutline } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { useMyContext } from "../context/Usecontext";
import conf from "../config";
import Cookies from "js-cookie";
import { AiOutlineDelete } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import { FaWpforms } from "react-icons/fa6";
import { FaArchive } from "react-icons/fa";
import { GoArchive } from "react-icons/go";
const Showdata = ({ editdata, setIslogin }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchYear, setSearchYear] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [showmodal, setShowmodal] = useState(false);
  const [deleteid, setDeleteid] = useState();
  const [sortstate, setSortstate] = useState("ascending");
  const [empdetails, setEmpdetails] = useState([]);
  const { fname, setFname } = useMyContext();
  const { lname, setLname } = useMyContext();
  const [forminfo, setForminfo] = useState({});

  const columns = [
    // Define columns based on your form fields
    {
      title: "Year",
      dataIndex: "Year",
      key: "Year",
    },
    {
      title: "First Name",
      dataIndex: "fname",
      key: "fname",
    },
    {
      title: "Last Name",
      dataIndex: "lname",
      key: "lname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit Record" color="green">
            <Button
              onClick={() => {
                editdata(text.id, navigate);
                // console.log(text.id);
                // setEmpidforedit(text.personid);
                // navigate("/form");
              }}
              type="primary"
            >
              <FiEdit />
            </Button>
          </Tooltip>
          {fname === "BV" && lname === "Admin" && (
            <Tooltip title="Delete Record" color="red">
              <Button
                type="primary"
                onClick={() => {
                  setDeleteid(record.id);
                  setShowmodal(true);
                }}
              >
                <AiOutlineDelete color={"red"} />
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Edit Employee Net Income Data" color="blue">
            <Button type="primary" onClick={() => netincomeform(record)}>
              <FaWpforms />
            </Button>
          </Tooltip>
          <Tooltip
            title={record.isArchieve ? "Unarchive Record" : "Archive Record"}
            color={record.isArchieve ? "blue" : "orange"}
          >
            <Button type="primary" onClick={() => toggleArchive(record)}>
              {record.isArchieve ? <FaArchive /> : <GoArchive />}
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const toggleArchive = async (record) => {
    try {
      const token = Cookies.get("token"); // Replace 'yourTokenCookieName' with the actual name of your token cookie
      const config = {
        headers: {
          Authorization: token,
        },
      };

      // const id=data.id;
      const id = record.id;
      const archiveresponse = await axios.put(
        `${conf.baseurl}/archiveyeardata/${id}`,
        {},
        config
      );
      if (archiveresponse.data.status === "S") {
        const empid = forminfo?.info;
        const year = forminfo.year?.$y;
        const response = await axios.post(
          `${conf.baseurl}/getemployeedata`,
          { empid: empid, year: year },
          config
        );

        if (response.data == "F") {
          toast.error("Data not found");
          setData([]);
        } else {
          setData(response.data.data);
          toast.success(archiveresponse.data.message);
        }
      } else {
        toast.error(archiveresponse.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else if (error.response && error.response.status === 403) {
        // console.log(error.response);
        toast.error(error.response.data.message);
      } else {
        console.log("Error in archieve data:", error.message);
      }
    }
  };

  const netincomeform = (record) => {
    // console.log(record);
    navigate(`/netincomeform/${record.personid}/${record.Year}`);
  };

  const getdata = async () => {
    try {
      setLoading(true);

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/getdata`, config);

      if (response.data == "F") {
        // console.log(response.data);
      } else {
        const sortedData = response.data.data.sort((a, b) => {
          return a.firstname.localeCompare(b.firstname); // Sort by first name in ascending order if years are equal
        });
        setData(sortedData);
        setOriginalData(response.data.data);

        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      // console.log('Error fetching data:', error.message);
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        setLoading(false);
        console.log("Error fetching data:", error.message);
      }
    }
  };

  const getdetails = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/getdetails`, config);
      if (response.data == "F") {
        console.log("no data available");
      } else {
        const data = response.data.filter((item) => item.isAdmin != 1);
        setEmpdetails(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    getdetails();
    setSearchYear("");
  }, []);

  const handleCancel = () => {
    setShowmodal(false);
    setDeleteid();
  };

  const [form] = Form.useForm();

  const handleReset = () => {
    form.resetFields();
    setData(originalData);
  };

  const deleteEmployeedata = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const id = deleteid;
      // console.log(id);
      const response = await axios.delete(
        `${conf.baseurl}/delete_employeedata/${id}`,
        config
      );
      if (response.data.status === "S") {
        toast.success(response.data.message);
        setShowmodal(false);
        setDeleteid();
        // getdata();
        setData([]);
        setSearchYear("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else if (error.response && error.response.status === 403) {
        console.log(error.response);
        toast.error(error.response.data.message);
      } else {
        console.log("Error in deleting data:", error.message);
      }
    }
  };

  const onFinish = async (data) => {
    try {
      console.log(data);
      setForminfo(data);
      console.log(data);
      const empid = data?.info;
      const year = data.year?.$y;
      if (!empid && !year) {
        toast.error("First select employee or year");
      } else {
        setLoading(true);

        const token = Cookies.get("token");
        const config = {
          headers: {
            Authorization: token,
          },
        };
        const response = await axios.post(
          `${conf.baseurl}/getemployeedata`,
          { empid: empid, year: year },
          config
        );

        if (response.data == "F") {
          toast.error("Data not found");
          setLoading(false);
          setData([]);
        } else {
          // console.log(response.data);
          setData(response.data.data);
          setLoading(false);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        setLoading(false);
        console.log("Error fetching data:", error.message);
      }
    }
  };

  return (
    <div className="container">
      <Spin spinning={loading}>
        <div className="formWrapper">
          <div
            style={{
              margin: "0 10px 10px 10px",
            }}
          >
            <div>
              <Form onFinish={onFinish} form={form}>
                <Row>
                  <Col span={9}>
                    <Form.Item name="year">
                      <DatePicker
                        // allowClear={false}
                        picker="year"
                        format="YYYY"
                        onChange={(year) => {
                          console.log(year);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item name="info">
                      <Select
                        // style={{ width: 300, marginLeft: 20 }}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {empdetails.map((val) => {
                          return (
                            <Select.Option value={val.id} key={val.id}>
                              {val.fname + " " + val.lname + " " + val.mail}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="view-btn"
                    style={{ marginLeft: 30 }}
                  >
                    <LuEye size={20} />
                  </Button>
                  <Button
                    className="defaultBtns"
                    style={{ marginLeft: 30 }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    className="defaultBtns"
                    onClick={() => {
                      navigate("/form");
                    }}
                    style={{ marginLeft: 30 }}
                  >
                    <MdAddCircleOutline />
                    Add
                  </Button>
                </Row>
              </Form>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={data}
            style={{ overflow: "auto", margin: 10 }}
            pagination={false}
            align="center"
            className="grid-table"
          />

          <Modal
            open={showmodal}
            onOk={deleteEmployeedata}
            onCancel={handleCancel}
          >
            <p>Are you sure you want to delete this data ?</p>
          </Modal>
        </div>
      </Spin>
    </div>
  );
};
export default Showdata;
